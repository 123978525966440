
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
    components: {
        userImage: () => import("@/components/general/user-image.vue"),
    },
})
export default class PageSellerPartContactCard extends Vue {
    @Prop({ required: true }) contact!: ActivityAssignedTo;
    @Prop({ required: true }) label!: string;
    @Prop({ required: true }) description!: string;
}
